import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Button from "../components/Button"
import GoogleAdsense from "../components/GoogleAdsense"

export default function Poets({ data }) {
  const { allContentfulPoet } = data
  const { pageInfo } = allContentfulPoet

  return (
    <Layout>
      <Seo title="các tác giả" />
      <p className="italic">(danh sách tác giả đang được cập nhật)</p>
      <div className="flex flex-col space-y-2">
        {allContentfulPoet.edges.map(({ node }) => (
          <div key={node.id}>
            <Link to={`/poets/${node.slug}`} className="hover:underline">
              {node.penName}
            </Link>
          </div>
        ))}
      </div>
      <div>
        <div className="text-center text-gray-500">
          trang {pageInfo.currentPage}/{pageInfo.pageCount}
        </div>
        <div className="flex justify-center space-x-4 sm:space-x-6 pt-5">
          <Link
            disabled={!pageInfo.hasPreviousPage}
            style={{
              pointerEvents: pageInfo.hasPreviousPage ? "auto" : "none",
            }}
            to={`/poets`}
          >
            <Button disabled={!pageInfo.hasPreviousPage}>đầu</Button>
          </Link>
          <Link
            disabled={!pageInfo.hasPreviousPage}
            style={{
              pointerEvents: pageInfo.hasPreviousPage ? "auto" : "none",
            }}
            to={`/poets${
              pageInfo.currentPage - 1 === 1
                ? ""
                : `/page/${pageInfo.currentPage - 1}`
            }`}
          >
            <Button disabled={!pageInfo.hasPreviousPage}>trước</Button>
          </Link>
          <Link
            disabled={!pageInfo.hasNextPage}
            style={{ pointerEvents: pageInfo.hasNextPage ? "auto" : "none" }}
            to={`/poets/page/${pageInfo.currentPage + 1}`}
          >
            <Button disabled={!pageInfo.hasNextPage}>tiếp</Button>
          </Link>
          <Link
            disabled={!pageInfo.hasNextPage}
            style={{ pointerEvents: pageInfo.hasNextPage ? "auto" : "none" }}
            to={`/poets/page/${pageInfo.pageCount}`}
          >
            <Button disabled={!pageInfo.hasNextPage}>cuối</Button>
          </Link>
        </div>
      </div>
      <GoogleAdsense
        client="ca-pub-1804168523618722"
        slot="3767743010"
        className="google-ads-footer"
      />
    </Layout>
  )
}

export const query = graphql`
  query ($limit: Int!, $skip: Int!) {
    allContentfulPoet(
      limit: $limit
      skip: $skip
      sort: { fields: slug, order: ASC }
    ) {
      edges {
        node {
          id
          slug
          penName
        }
      }
      pageInfo {
        itemCount
        pageCount
        perPage
        totalCount
        hasNextPage
        currentPage
        hasPreviousPage
      }
    }
  }
`
